@import url('https://fonts.cdnfonts.com/css/gotham');

body {
  margin: 0;
  font-family: 'Gotham', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fafafa;
  
}

@media screen and (max-width: 450px) {
  .card-flutuante{
    display: none;
  }
  .mobile-view{
    display: initial;
  }
  .mobile-hidden{
    display: none;
  }
  .home-gradient-background{
    background-image: linear-gradient(to right, #D38039e1, #D3803933);
  }
  .home-background-position{
    background-position: center;
  }
  .card-loading-width{
    width: 19.6rem;
  }
}

@media screen and (min-width: 451px) {
  .home-gradient-background{
    background-image: linear-gradient(to right, #D38039, #D38039, #D3803955, transparent, transparent);
  }
  .card-loading-width{
    width: auto;
  }
  .pc-hidden{
    display: none;
  }
  .card-flutuante{
    top: 1rem; /* altura que vai parar antes do topo */
    position: sticky;
    float: right;
  }
  .mobile-view{
    display: none;
  }
  .carousel-custom li{
    margin-right: 0.1rem;
  }
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
}
.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #bcbcbc;
}
.separator::before {
  margin-right: .25em;
}
.separator::after {
  margin-left: .25em;
}


::-webkit-scrollbar {
  /*display: none;*/
}

.scroll-bar-custom::-webkit-scrollbar-track{
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
	border-radius: 15px;
	background-color: #D0D4CE;
}

.scroll-bar-custom::-webkit-scrollbar{
  height: 0.5rem;
	background-color: #D0D4CE;
  border-radius: 15px;
}

.scroll-bar-custom::-webkit-scrollbar-thumb{
  border-radius: 15px;
	box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #D38039;
  height: 0.5rem;
}

.is-loading{
  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
}


@keyframes shine {
to {
  background-position-x: -200%;
}
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
